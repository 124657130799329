/* MIXINS */
/* border radius */
.br(@br: @border-radius) {
	border-radius: @br;
}
.br(@br1, @br2) {
	border-radius: @br1 @br2;
}
.br(@br1, @br2, @br3) {
	border-radius: @br1 @br2 @br3;
}
.br(@br1, @br2, @br3, @br4) {
	border-radius: @br1 @br2 @br3 @br4;
}
/* size */
.size(@x) {
	height: @x;
	width: @x;
}
.size(@width, @height) {
	height: @height;
	width: @width;
}
/* letter spacing */
.ls-px(@value, @font-size: @font-size) {
	letter-spacing: @value * @font-size / 1000;
}
.ls(@value) {
	letter-spacing: @value * 1em / 1000;
}

/* transition */
.trs(@setting: all, @time: @transition-speed) {
	transition: @setting @time;
}

/* reset */
.reset() {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* addition classes */
.d-f() {
	display: flex;
}
.ai-c() {
	align-items: center;
}
.jc-c() {
	justify-content: center;
}
.title() {
	color: @black;
	font-style: normal;
	font-size: 40px;
	font-weight: bold;
	line-height: 1.2;
	margin: 0 0 1em;
	padding: 0;
	text-align: left;

	@media @xl {
		font-size: 36px;
	}
	@media @lg {
		font-size: 32px;
	}
	@media @md {
		font-size: 24px;
	}
}