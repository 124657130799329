@import "../_variables";
@import "../_mixins";

.specialists-section {
	.br(0, 200px, 200px, 0);

	background: @light-bg url(../../img/spec-bg.png) left bottom no-repeat;
	box-shadow: 10px 10px 50px rgba(51, 51, 51, 0.3);
	overflow: hidden;
	padding: 80px 0 100px;
	position: relative;
	z-index: 1;

	@media @xl {
		.br(0, 600px, 600px, 0);

		padding: 60px 0 65px;
	}
	@media @lg {
		.br(0);

		background-position: center center;
		background-size: cover;
		padding: 45px 0 40px;
	}
	@media @md {
		padding: 36px 0 32px;
	}
	@media @sm {
		padding: 24px 0;
	}

	&:before {
		.size(100%);

		background: rgba(255, 255, 255, 0.76);
		content: '';
		left: 0;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	> * {
		position: relative;
		z-index: 2;
	}

	&__title {
		.title;

		color: @red;

		@media @md {
			text-align: center;
		}
	}
	&__cont {
		margin-top: 60px;

		@media @xl {
			margin-top: 48px;
		}
		@media @lg {
			margin-top: 32px;
		}
		@media @md {
			margin-top: 24px;
		}
		@media @sm {
			margin-top: 16px;
		}
	}
	&__actions {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin-top: 12px;
		width: 100%;

		@media @xl {
			justify-content: center;
			margin-top: 24px;
		}
		@media @sm {
			margin-top: 20px;
		}
	}
}
.specialist-directions {
	columns: 4;
	column-gap: 20px;
	display: block;

	@media @lg {
		columns: 3;
	}
	@media @md {
		column-gap: 16px;
	}
	@media @sm {
		columns: 2;
		column-gap: 14px;
	}

	&__sumbol {
		display: inline-block;
		margin: 0 0 30px;
		width: 100%;

		@media @xl {
			margin-bottom: 24px;
		}
		@media @lg {
			margin-bottom: 20px;
		}
		@media @md {
			margin-bottom: 16px;
		}
		@media @sm {
			margin-bottom: 12px;
		}

		&-title {
			.title;

			color: @red;
			margin: 0 0 10px;

			@media @lg {
				margin-bottom: 8px;
			}
			@media @md {
				margin-bottom: 6px;
			}
			@media @sm {
				margin-bottom: 5px;
			}
		}
		&-list {
			display: block;
		}
		&-item {
			font-size: 16px;
			line-height: 1.2;
			margin: 0 0 20px;

			@media @lg {
				margin-bottom: 16px;
			}
			@media @md {
				font-size: 14px;
				margin-bottom: 12px;
			}
			@media @sm {
				font-size: 12px;
				bottom: 10px;
			}

			&:last-child {
				margin: 0;
			}
		}
		&-link {
			.trs;

			color: @black;
			text-decoration: none;

			&:hover {
				color: @red-1;
				text-decoration: none;
			}
		}
	}
}

