@import "../_variables";
@import "../_mixins";

.main-slider {
	margin: 0 0 20px;
	overflow: hidden;
	position: relative;

	&__wrapper {
		align-items: stretch;
		display: flex;
		justify-content: flex-start;
		position: relative;
		z-index: 1;
	}
	&__item {
		display: block;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		&-bg {
			.br(0, 0, 180px, 0);

			background: @light-bg;
			box-sizing: border-box;
			height: 100%;
			padding: 80px 200px 60px 0;
			position: absolute;
			width: 64.58%;

			@media @lg {
				padding: 40px 120px 34px 0;
				width: 70%;
			}
			@media @md {
				padding: 44px 88px 24px 0;
				width: 71%;
			}
			@media @sm {
				padding: 40px 64px 20px 0;
				width: 74%;
			}
		}
		&-img {
			.br(0, 140px, 140px, 0);

			display: block;
			height: 100%;
			object-fit: cover;
			object-position: center;
			overflow: hidden;
			width: 100%;
		}
		&-internal {
			align-items: center;
			box-sizing: border-box;
			display: flex;
			min-height: 610px;
			justify-content: flex-end;
			padding: 60px 0;
			position: relative;
			width: 100%;
			z-index: 2;

			@media @lg {
				min-height: 550px;
				padding: 20px 0 60px;
			}
			@media @md {
				min-height: 360px;
				padding: 20px 0 30px;
			}
			@media @sm {
				min-height: 240px;
				padding: 20px 0 24px;
			}
		}
		&-cont {
			display: block;
			width: 40%;

			@media @lg {
				width: 42%;
			}
			@media @sm {
				width: 46%;
			}
		}
		&-title {
			.title;

			color: @red;
			font-size: 36px;
			font-weight: bold;
			margin: 0 0 0.6em;

			@media @lg {
				font-size: 24px;
			}
			@media @sm {
				font-size: 16px;
			}
		}
		&-description {
			font-size: 24px;
			line-height: 1.05;

			@media @lg {
				font-size: 16px;
			}
			@media @sm {
				font-size: 12px;
			}
		}
		&-actions {
			margin: 25px 0 0;
		}
		&-btn {

		}
	}
	&__pagination {
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 3;

		&-internal {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin-bottom: 30px;

			@media @xl {
				margin-bottom: 20px;
			}
			@media @lg {
				margin-bottom: 10px;
			}
			@media @md {
				padding-right: 11%;
			}
			@media @sm {
				justify-content: center;
				margin-left: auto;
				margin-bottom: 16px;
				padding-right: 0;
				width: 46%;
			}
		}
	}

	.pagination-item {
		.br(50%);
		.size(15px);
		.trs;

		background: @white;
		border: 3px solid @gray;
		box-sizing: border-box;
		cursor: pointer;
		margin-right: 20px;

		@media @lg {
			.size(9px);

			margin-right: 11px;
		}
		@media @sm {
			.size(6px);

			border-width: 2px;
			margin-right: 6px;
		}

		&:last-child {
			margin: 0;
		}

		&:hover {
			border-color: @red;
		}

		&_active {
			background-color: @red;
			border-color: @red;
		}
	}
}