@import "../_variables";
@import "../_mixins";

.about-section {
	padding: 80px 0 85px;
	position: relative;

	@media @xl {
		padding: 60px 0 65px;
	}
	@media @lg {
		padding: 40px 0 48px;
	}
	@media @md {
		padding: 32px 0 36px;
	}
	@media @md {
		padding: 30px 0 32px;
	}

	&:before {
		.br(190px, 0, 0, 0);
		.size(100%);

		background: @white;
		bottom: 0;
		box-shadow: 10px -10px 50px rgba(51, 51, 51, 0.3);
		content: '';
		position: absolute;
		right: 0;
		z-index: 1;

		@media @md {
			.br(120px, 0, 0, 0);
		}
	}
	&:after {
		.br(190px, 0, 0, 0);
		.size(78%, 84%);

		background: @light-bg;
		bottom: 0;
		content: '';
		position: absolute;
		right: 0;
		z-index: 2;

		@media @md {
			.br(160px, 0, 0, 0);
		}
	}

	> * {
		position: relative;
		z-index: 3;
	}

	&__title {
		color: @red;
		font-size: 40px;
		font-weight: bold;
		line-height: 1.2;
		margin: 0 0 1em;
		padding: 0;

		@media @xl {
			font-size: 32px;
		}
		@media @lg {
			font-size: 24px;
		}
		@media @md {
			font-size: 16px;
		}
		@media @sm {
			font-size: 12px;
			padding-left: 32px;
		}
	}
	&__description {
		columns: 2;
		column-gap: 20px;
		display: block;
		font-size: 16px;
		line-height: 1.25;

		@media @lg {
			font-size: 14px;
		}
		@media @md {
			columns: 1;
			column-gap: 0;
			font-size: 12px;
		}
		@media @sm {
			font-size: 10px;
		}

		p {
			display: inline-block;
			margin: 1em 0;
			width: 100%;
		}
	}
	&__slider {
		margin-top: 80px;

		@media @xl {
			margin-top: 60px;
		}
		@media @lg {
			margin-top: 40px;
		}
		@media @md {
			margin-top: 32px;
		}
		@media @sm {
			margin-top: 24px;
		}

		&-show-more {
			font-size: 16px;
			line-height: 1.2;
			margin-top: 30px;
			text-align: center;

			@media @xl {
				margin-top: 24px;
			}
			@media @lg {
				font-size: 14px;
				margin-top: 20px;
			}
			@media @md {
				font-size: 12px;
				margin-top: 16px;
			}
			@media @sm {
				font-size: 10px;
				margin-top: 12px;
			}

			a {
				color: @red;
				text-decoration: underline;
				text-decoration-color:rgba(233, 58, 63, 0.5);

				&:hover {
					color: @red;
					text-decoration: none;
				}
			}
		}
	}
}

.gallery-slider {
	overflow: hidden;
	position: relative;

	&__wrapper {
		align-items: center;
		display: flex;
		justify-content: flex-start;
	}
	&__item {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		flex-shrink: 0;
		height: 350px;
		justify-content: center;
		margin: 0;
		padding: 0 10px;
		width: 580px;

		@media @xl {
			height: 276px;
			width: 460px;
		}
		@media @lg {
			height: 192px;
			padding: 0 20px;
			width: 320px;
		}
		@media @md {
			height: 240px;
			padding: 0 40px;
			width: 280px;
		}
		@media @sm {
			height: 210px;
			width: 240px;
		}

		&_active {
			.gallery-slider__link {
				@media @md-up {
					.br(40px);
					.size(100%);
				}
			}
		}
		&_prev {
			justify-content: flex-end;
		}
		&_next {
			justify-content: flex-start;
		}
	}
	&__link {
		.br(40px);
		.size(100%);

		display: block;
		overflow: hidden;

		@media @md-up {
			.br(20px);
			.size(31%);

			transition: all .3s;
		}
	}
	&__img {
		display: block;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	&__action {
		.br(50%);
		.size(50px);

		align-items: center;
		background: @red;
		color: @white;
		cursor: pointer;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 5;

		@media @xl {
			.size(40px)
		}
		@media @lg {
			.size(32px);
		}
		@media @md {
			.size(24px);
		}
		@media @sm {
			.size(20px);
		}

		&:before {
			.size(15px);

			border: 2px solid currentColor;
			border-right-width: 0;
			border-top-width: 0;
			content: '';

			@media @xl {
				.size(12px)
			}
			@media @lg {
				.size(10px);
			}
			@media @md {
				.size(8px);
			}
			@media @sm {
				.size(6px);
			}
		}

		&_prev {
			left: 30px;

			@media @xl {
				left: 20px;
			}
			@media @lg {
				left: 12px;
			}
			@media @md {
				left: 6px;
			}
			@media @sm {
				left: 2px;
			}

			&:before {
				transform: rotate(45deg) translate(15%, -15%);
			}
		}
		&_next {
			right: 30px;

			@media @xl {
				right: 20px;
			}
			@media @lg {
				right: 12px;
			}
			@media @md {
				right: 6px;
			}
			@media @sm {
				right: 2px;
			}

			&:before {
				transform: rotate(225deg) translate(15%, -15%);
			}
		}
	}
}