@import "../_variables";
@import "../_mixins";

.jumbotron {
	margin-bottom: -20px;
	padding: 0 0 60px;
	position: relative;
	z-index: 2;

	@media @xl {
		padding-bottom: 32px;
	}
	@media @lg {
		padding-bottom: 24px;
	}
	@media @md {
		padding-bottom: 16px;
	}
	@media @sm {
		padding-bottom: 8px;
	}

	&:before {
		.br(0, 0, 200px, 0);

		background: @white;
		box-shadow: 10px 10px 50px rgba(51, 51, 51, 0.3);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 84%;
		z-index: 1;

		@media @xl {
			width: 88%;
		}
		@media @lg {
			width: 92%;
		}
		@media @md {
			width: 96%;
		}
		@media @sm {
			width: 100%;
		}
	}

	> * {
		position: relative;
		z-index: 2;
	}

	&__top {
		.br(0, 0, 0, 200px);

		align-items: center;
		background: @purple;
		display: flex;
		justify-content: flex-end;
		margin: 0 0 -32px;
		padding: 0 0 85px;
		position: relative;

		@media @xl {
			margin-bottom: -24px;
			padding-bottom: 64px;
		}
		@media @lg {
			margin-bottom: -16px;
			padding-bottom: 46px;
		}
		@media @md {
			margin-bottom: 6px;
			padding-bottom: 32px;
		}
		@media @sm {
			padding-bottom: 26px;
		}

		&-img-wrap {
			.br(0, 0, 0, 180px);

			background: @white;
			box-sizing: border-box;
			padding: 8px 0 8px 16.3%;
			position: relative;
			width: 85.4%;
			z-index: 1;

			@media @xl {
				padding: 6px 0 6px 16.3%;
			}
			@media @lg {
				padding: 4px 0 4px 16.3%;
			}
			@media @sm {
				padding: 2px 0 2px 16.3%;
			}
		}
		&-img {
			.br(0, 0, 0, 180px);

			display: block;
			overflow: hidden;
			width: 100%;
		}
		&-cont {
			left: 0;
			position: absolute;
			width: 100%;
			z-index: 2;
		}
		&-slogan {
			color: @red;
			display: block;
			font-size: 48px;
			font-weight: 500;
			line-height: 1.15;
			margin: 0;
			max-width: 55%;
			padding: 0;
			text-transform: uppercase;

			@media @xl {
				font-size: 40px;
			}
			@media @lg {
				font-size: 32px;
			}
			@media @md {
				font-size: 26px;
			}
			@media @sm {
				font-size: 21px;
			}
		}
	}
}

.jumbotron-news {
	align-items: stretch;
	display: flex;
	justify-content: space-between;

	@media @md {
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
	}

	&__item {
		align-items: stretch;
		display: flex;
		flex: 1;
		justify-content: stretch;
		margin-right: 20px;

		&:last-child {
			margin: 0;
		}

		@media @md {
			margin: 0 0 12px;
			width: 60%;

			&:nth-child(even) {
				margin-left: auto;
			}
		}
		@media @sm {
			margin-bottom: 6px;
			width: 72%;
		}
	}
	&__link {
		.br(30px);
		.trs;

		background: @red;
		color: @white;
		display: block;
		padding: 36px 80px;
		position: relative;
		text-decoration: none;

		@media @xl {
			padding: 28px 54px 28px 40px;
		}
		@media @lg {
			padding: 20px 40px;
		}
		@media @md {
			padding: 12px 36px;
		}
		@media @sm {
			padding: 8px 32px;
		}

		&:hover {
			background-color: @red-1;
			color: @white;
			text-decoration: none;

			.jumbotron-news__link-arrow {
				color: @red-1;
			}
		}

		&-text {
			display: block;
			font-size: 24px;
			line-height: 1.05;
			min-height: 3 * 1.05em;

			@media @xl {
				font-size: 20px;
				line-height: 1.1;
			}
			@media @lg {
				font-size: 16px;
				line-height: 1.2;
			}
			@media @md {
				font-size: 14px;
				line-height: 1.25;
			}
			@media @sm {
				font-size: 12px;
				line-height: 1.33;
			}
		}
		&-arrow {
			.br(50%);
			.size(50px);

			align-items: center;
			background: @white;
			bottom: 25px;
			color: @red;
			display: flex;
			justify-content: center;
			position: absolute;
			right: 25px;

			@media @xl {
				.size(32px);

				right: 16px;
			}
			@media @lg {
				.size(28px);

				bottom: 50%;
				right: 12px;
				transform: translateY(50%);
			}
			@media @md {
				.size(24px);

				bottom: 16px;
				transform: none;
			}
			@media @sm {
				.size(22px);

				bottom: 12px;
			}

			.icon {
				.size(29px, 16px);

				display: block;

				@media @xl {
					.size(20px);
				}
				@media @lg {
					.size(16px);
				}
				@media @md {
					.size(14px);
				}
				@media @sm {
					.size(12px);
				}
			}
		}
	}
}