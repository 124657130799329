@import "../_variables";
@import "../_mixins";

.service-section {
	.br(200px, 0, 0, 0);

	background: @red;
	color: @white;
	margin-bottom: -190px;
	padding: 80px 0 270px;

	@media @xl {
		.br(120px, 0, 0, 0);

		margin-bottom: -390px;
		padding: 32px 0 420px;
	}
	@media @lg {
		.br(120px, 0, 0, 120px);

		margin-bottom: 0;
		padding: 30px 0;
	}

	&__title {
		.title;

		color: inherit;
		margin: 0 0 0.8em;
		padding-left: 20px;

		@media @xl {
			margin-bottom: 0.6em;
			padding-left: 40px;
		}
		@media @lg {
			margin-bottom: 0.5em;
			padding-left: 0;
			text-align: center;
		}
	}
	&__list {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		> * {
			box-sizing: border-box;
			flex: 1;
			min-width: 25%;

			@media @xl {
				min-width: 33.33%;
			}
			@media @md {
				min-width: 50%;
			}
		}
	}
	&__actions {
		display: block;
		padding: 20px 0 0 20px;

		@media @lg {
			padding: 10px 0 0 10px;
		}
		@media @md {
			padding: 6px 0 0;
		}
	}
	&__btn {
		margin: 0 0 20px;
		width: 100%;

		@media @xl {
			margin-bottom: 12px;
		}
		@media @sm {
			margin-bottom: 16px;
		}

		&:last-child {
			margin: 0;
		}
	}
}

.service-link {
	.br(30px);
	.trs;

	background: transparent;
	color: inherit;
	display: block;
	margin: 0 0 25px;
	padding: 20px 20px 14px;
	text-decoration: none;

	@media @lg {
		padding: 10px 20px 24px;
	}
	@media @sm {
		padding: 6px 12px 12px;
	}

	&:hover {
		background-color: @red-1;
		color: inherit;
		text-decoration: underline;
	}

	&__icon {
		.size(50px);

		display: block;
		margin: 0 0 10px;
		object-fit: contain;
		object-position: center;

		@media @lg {
			.size(36px);

			margin-bottom: 6px;
		}
		@media @sm {
			.size(30px);

			margin-bottom: 12px;
		}
	}
	&__name {
		font-size: 16px;
		line-height: 1em;
		min-height: 2em;

		@media @lg {
			font-size: 14px;
		}
		@media @sm {
			font-size: 12px;
		}
	}
}