@import "../_variables";
@import "../_mixins";

.video-section {
	padding: 130px 0 195px;
	position: relative;

	@media @xl {
		padding: 60px 0 125px;
	}
	@media @lg {
		padding: 45px 0 95px;
	}
	@media @md {
		padding: 36px 0 64px;
	}
	@media @md {
		padding: 32px 0 48px;
	}

	&:before {
		.br(0, 0, 200px, 0);
		.size(77%, 90%);

		background: @light-bg;
		content: '';
		left: 0;
		position: absolute;
		top: 0;
		z-index: 1;

		@media @md {
			.br(0, 0, 120px, 0);
		}
	}
	&:after {
		.br(0, 0, 200px, 0);
		.size(63.3%, 87.5%);

		background: @white;
		content: '';
		left: 0;
		position: absolute;
		top: 0;
		z-index: 2;

		@media @md {
			.br(0, 0, 160px, 0);
		}
	}

	> * {
		position: relative;
		z-index: 3;
	}

	&__internal {
		align-items: stretch;
		display: flex;
		justify-content: flex-start;
	}
	&__main {
		flex: 1;
		height: 350px;
		margin-right: 20px;
		max-width: 580px;
		position: relative;

		@media @lg {
			height: 280px;
			max-width: 480px;
		}
		@media @md {
			height: 210px;
			max-width: 380px;
		}
		@media @sm {
			height: 170px;
			max-width: 310px;
		}
		@media @xs {
			height: 130px;
			max-width: 240px;
		}

		> iframe {
			.size(100%);

			left: 0;
			position: absolute;
			top: 0;
		}
	}
	&__cont {
		margin: 0 auto;
		width: 400px;

		@media @lg {
			margin-right: initial;
			width: 240px;
		}
		@media @md {
			width: 160px;
		}
		@media @sm {
			width: 120px;
		}
	}
	&__title {
		.title;
	}
	&__thumbs {
		.size(100%, 230px);

		display: block;
		position: relative;

		@media @lg {
			height: 168px;
		}
		@media @md {
			height: 168px;
			width: 160px;
		}
		@media @sm {
			height: 100px;
			width: 100px;
		}
	}
}
.video-thumbs {
	.size(100%);

	margin: auto;
	overflow: hidden;

	&__wrapper {
		.size(100%);

		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@media @md {
			flex-direction: row;
		}
	}
	&__item {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		justify-content: flex-start;
		width: 100%;

		@media @md {
			flex-direction: column;
			height: 100%;
		}

		> * {
			flex: 1;

			&:first-child {
				margin-right: 40px;

				@media @lg {
					margin-right: 24px;
				}
				@media @md {
					margin-bottom: 8px;
					margin-right: 0;
				}
			}
		}

		.video-block {
			.size(180px, 100px);

			@media @lg {
				.size(120px, 67px);
			}
			@media @md {
				.size(140px, 75px);
			}
			@media @sm {
				.size(90px, 50px);
			}
		}
	}
	&__action {
		.br(50%);
		.size(50px);
		.trs;

		align-items: center;
		background: @red;
		color: @white;
		cursor: pointer;
		display: flex;
		justify-content: center;
		left: 50%;
		opacity: 1;
		position: absolute;
		z-index: 5;

		@media @xl {
			.size(40px)
		}
		@media @lg {
			.size(32px);
		}
		@media @md {
			.size(24px);
		}
		@media @sm {
			.size(20px);
		}

		&:before {
			.size(15px);

			border: 2px solid currentColor;
			border-right-width: 0;
			border-top-width: 0;
			content: '';

			@media @xl {
				.size(12px)
			}
			@media @lg {
				.size(10px);
			}
			@media @md {
				.size(8px);
			}
			@media @sm {
				.size(6px);
			}
		}

		&.disabled {
			opacity: 0;
		}

		&_prev {
			top: 0;
			transform: translate(-50%, -50%);

			@media @md {
				left: 0;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			&:before {
				transform: rotate(135deg) translate(15%, -15%);

				@media @md {
					transform: rotate(45deg) translate(15%, -15%);
				}
			}
		}
		&_next {
			bottom: 0;
			transform: translate(-50%, 50%);

			@media @md {
				bottom: initial;
				left: initial;
				right: 0;
				top: 50%;
				transform: translate(50%, -50%);
			}

			&:before {
				transform: rotate(315deg) translate(15%, -15%);

				@media @md {
					transform: rotate(225deg) translate(15%, -15%);
				}
			}
		}
	}
}